<template>
  <div class="select-import">
    <b-img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/41b259a0d3401a7408428719d04768b16fc3762a9d031c12640aab6c94c8877c?apiKey=d392fee9fbc34ca08c0b35b988671a63"
      fluid
      class="background-image"
      alt="Background"
    />
    <b-container class="grading-options">
      <section>
        <b-row>
          <b-col>
            <h1 class="title">What would you like to do?</h1>
          </b-col>
        </b-row>
        <b-row class="options-container">
          <b-col md="6" class="mb-4">
            <b-card class="option-card">
              <h2 class="option-title">
                <img
                  class="option-icon mr-2"
                  src="../../assets/icons/stars.svg"
                  alt=""
                />
                Upload essays
              </h2>
              <p class="option-description">
                Grade essays in bulk using custom or default rubrics.
              </p>
              <b-button to="/upload-essays" variant="info">Upload</b-button>
            </b-card>
          </b-col>
          <b-col md="6" class="mb-4">
            <b-card class="option-card">
              <h2 class="option-title">
                <img
                  class="option-icon mr-2"
                  src="../../assets/icons/g-classroom.svg"
                  alt=""
                />
                Import from Google Classroom
              </h2>
              <p class="option-description">
                Grade imported assignments using custom or default rubrics.
              </p>
              <b-button to="/imports/google-classroom" variant="info">
                Import
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>

      <section>
        <b-row>
          <b-col>
            <h1 class="title">Community Update</h1>
          </b-col>
        </b-row>
        <b-row class="options-container">
          <b-col md="12">
            <b-card class="option-card banner">
              <div>
                <h2 class="option-title">EG 2.1 Webinar</h2>
                <div class="option-description">
                  <div><b>Date:</b> October 8, 2024</div>
                </div>
                <b-button
                  class="register-now"
                  href="https://drive.google.com/file/d/1Tbz2ybKZqYtb2oqOev4zibarS9Dt1ov4/view?usp=sharing"
                  target="_blank"
                  variant="Link"
                >
                  View Recording
                </b-button>
              </div>
              <div class="divider"></div>
              <div>
                <h2 class="option-title">Highlights</h2>
                <div class="option-description">
                  <ul>
                    <li>
                      <b>New Grading Features:</b> We previewed EG 2.2 and EG
                      2.3 releases featuring handwriting recognition,
                      text-dependency analysis, and CSV export options.
                    </li>
                    <li>
                      <b>Teacher Q&A:</b> Sammy Young from La Vernia ISD
                      answered insightful questions on grading workflows and
                      having better student conferences with EssayGrader™.
                    </li>
                    <li>
                      <b>Feature Requests:</b> Our teachers shared brilliant
                      ideas for enhancing EssayGrader™, and we already started
                      working on them!
                    </li>
                  </ul>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GradingOptions",
};
</script>

<style scoped lang="scss">
.select-import {
  position: relative;
}

.grading-options {
  position: relative;
  min-height: 700px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.title {
  color: #000;
  font: 500 32px/119% Roboto, sans-serif;
  margin-left: 16px;
  position: relative;
}

.options-container {
  position: relative;
  margin: 25px 0 0;
}

.option-card {
  height: 100%;
}

.option-title {
  color: #000;
  font: 500 20px/120% Roboto, sans-serif;
  margin-bottom: 12px;
}

.option-icon {
  width: 32px;
  height: 32px;
}

.option-description {
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.option-button {
  font-family: Roboto, sans-serif;
  background-color: #6f42c1;
  border-color: #6f42c1;
}

@media (max-width: 991px) {
  .grading-options {
    padding: 27px 20px;
  }
}

.banner {
  background-image: url("../../assets/banner-top.png");
  background-size: calc(100% + 2px) 8px;
  background-position: -1px -1px;
  background-repeat: no-repeat;

  .card-body {
    display: flex;

    & > div:first-child {
      flex-shrink: 0;
      width: 170px;
      color: hsla(210, 11%, 15%, 1);
    }

    .divider {
      width: 1px;
      height: 100%;
      margin: 0 16px;
      background-color: hsla(0, 0%, 87%, 1);
    }

    .option-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      ul {
        padding-left: 20px;
      }
    }

    .register-now {
      color: hsla(257, 61%, 56%, 1);
      padding: 0;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .text-additional {
      font-size: 12px;
      font-style: italic;
      line-height: 24px;
      color: hsla(210, 11%, 15%, 1);
    }
  }
}
</style>
