import Vue from "vue";
import Hotjar from "vue-hotjar";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import * as Sentry from "@sentry/vue";

import "./assets/main.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$showToastError = function (
  errorMessage,
  options = {
    title: "Error",
  }
) {
  this.$bvToast.toast(errorMessage, {
    ...options,
    variant: "danger",
  });
};

Vue.use(Hotjar, {
  id: "4982392",
  isProduction: process.env.NODE_ENV === "production",
  snippetVersion: 6,
});

Sentry.init({
  Vue,
  dsn: "https://026ab1ddd5871b2b08750822799c8fc5@o4507063370121216.ingest.us.sentry.io/4507063385260032",
  environment: process.env.NODE_ENV,
});

Vue.config.productionTip = false;

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
