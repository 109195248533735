import { app } from "@/main";
import store from "@/store";
import { unpause } from "./requests/subscription";
const ignoredUrls = ["/login", "/register", "/forgot-password"]; // show error directly on page (Alert)

// errorHandler is used to intercept when jwt token has expired
// and then logs out the user. They need to log back in to
// generate a new valid jwt token

export default function errorHandler(error) {
  if (ignoredUrls.includes(error.response.config.url)) {
    return Promise.reject(error);
  }

  const status = error.response?.status;

  if (status === 401) {
    store.dispatch("logout");
    location.pathname = "/login?ref=expired";
  } else {
    const id = `error-toast-${Date.now()}`;
    const code = error.response?.data?.code;
    let message =
      error?.response?.data?.errors?.[0]?.message ||
      error?.response?.data?.error ||
      "There was a problem processing your request. Please try again or contact support.";
    let title = "Error";

    if (code) {
      const h = app.$createElement;
      switch (code) {
        case "pausedLimitsExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              "Your plan is paused and you currently only have access to basic features. Resume your subscription to regain all the benefits you know and love",
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info" },
                  on: {
                    click: async () => {
                      await unpause(app);
                      app.$bvToast.hide(id);
                    },
                  },
                },
                "Resume"
              ),
            ]),
          ];
          break;
        }
        case "planWordLimitsExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              "The essay you are trying to upload is longer than your plan allows.",
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info", to: "/account" },
                },
                "Click here to upgrade"
              ),
            ]),
          ];
          break;
        }
        case "planEssayLimitsExceeded": {
          message = [
            h("p", { class: ["text-center", "mb-0"] }, [
              "You have reached your monthly essay limit.",
              h("br"),
              h(
                "b-button",
                {
                  class: ["mt-2"],
                  props: { variant: "info", to: "/account" },
                },
                "Click here to upgrade"
              ),
            ]),
          ];
          break;
        }
        case "missedPermissions":
          app.$store.dispatch("setUser", {
            ...app.$store.state.user,
            isGCAvailable: false,
          });

          title = "Google Permissions Needed";
          message =
            "You need to grant the necessary permissions to access Google's API.";
          break;
        case "badGoogleResponse":
          message =
            "We experienced an error processing your request. Please try again.";
          break;

        default:
          message = error.response?.data?.message || message;
          break;
      }
    }

    app.$bvToast.toast(message, {
      id,
      title,
      variant: "danger",
    });
  }

  return Promise.reject(error);
}
