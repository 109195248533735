<template>
  <auth-box
    :title="step === 0 ? 'Sign up to start grading' : ''"
    :error="error"
  >
    <div class="px-5" v-if="step === 0">
      <div class="text-center mb-4">
        <google-button
          class="mb-4 w-100"
          native
          type="registration"
          @done="handleDone"
        >
          Sign up with Google
        </google-button>

        <div class="divider">
          <div class="or">or</div>
        </div>
      </div>

      <b-form @submit.prevent="onSubmitEmail">
        <b-form-group
          id="email-group"
          label="Email Address"
          label-for="email-input"
        >
          <b-form-input
            ref="emailInput"
            id="email-input"
            v-model="email"
            type="email"
            :state="showEmailState ? emailState : null"
            placeholder="Enter email address"
            required
          ></b-form-input>
          <b-form-invalid-feedback id="email-input-error">
            Invalid email format
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button
          type="submit"
          :disabled="email.length === 0 || !email.includes('@') || loading"
          class="register-btn w-100"
          variant="info"
        >
          Next
        </b-button>
      </b-form>

      <div class="small-text mt-3 mb-4">
        By signing up or creating an account, you agree to our
        <a href="https://essaygrader.ai/terms-of-service" target="_blank">
          Terms of Service
        </a>
        and
        <a href="https://essaygrader.ai/privacy-policy" target="_blank">
          Privacy Policy </a
        >.
      </div>

      <div class="divider"></div>

      <p class="mt-4 text-center">
        Already have an account?
        <router-link to="/login">Log in</router-link>
      </p>
    </div>

    <eg-stepper
      v-if="step === 1"
      class="mt-4"
      v-model="loading"
      finish-label="Sign Up"
      :stepsTitles="stepsTitles"
      @beforeChange="beforeChange"
      @finish="onSubmit"
    >
      <template #slot-1>
        <b-form>
          <b-form-group
            id="password-group"
            label="Password"
            label-for="password-input"
          >
            <b-form-input
              ref="passwordInput"
              id="password-input"
              v-model="password"
              type="password"
              :state="password.length >= 8 || null"
              placeholder="Enter password"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="confirm-password-group"
            label="Confirm Password"
            label-for="confirm-password-input"
          >
            <b-form-input
              id="confirm-password-input"
              v-model="confirmedPassword"
              type="password"
              :state="passwordState"
              placeholder="Enter confirmed password"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="input-confirmed">
              Please make sure your password matches and has a password length
              of 8 characters or more.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </template>
      <template #slot-2>
        <b-form>
          <b-form-group
            id="first-name-group"
            label="First Name"
            label-for="first-name-input"
          >
            <b-form-input
              id="first-name-input"
              v-model="firstName"
              :state="showNameState ? firstName.length >= 1 : null"
              placeholder="Enter first name"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="first-name-input-error">
              Please enter your first name to continue.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="last-name-group"
            label="Last Name"
            label-for="last-name-input"
          >
            <b-form-input
              id="last-name-input"
              v-model="lastName"
              :state="showNameState ? lastName.length >= 1 : null"
              placeholder="Enter last name"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="last-name-input-error">
              Please enter your last name to continue.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </template>
    </eg-stepper>
  </auth-box>
</template>

<script>
import isEmail from "validator/es/lib/isEmail";
import { register } from "../../api";
import gTagMixin from "./gTagMixin";
import loginRedirectMixin from "./loginRedirectMixin";
import GoogleButton from "./google_button.vue";
import AuthBox from "./auth_box.vue";
import EgStepper from "@/components/global/eg_stepper.vue";

export default {
  name: "RegisterForm",

  components: {
    GoogleButton,
    AuthBox,
    EgStepper,
  },

  mixins: [
    // this.redirectToRoute
    loginRedirectMixin,
    // this.gtag_report_conversion
    // this.bing_report_conversion
    gTagMixin,
  ],

  data() {
    return {
      step: 0,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmedPassword: "",
      error: "",
      loading: false,

      showEmailState: false,
      showNameState: false,
      forcePasswordState: null,

      stepsTitles: [
        "Step one: Create a password",
        "Step two: Tell us about yourself",
      ],
    };
  },

  computed: {
    emailState() {
      return this.email.length === 0 ? null : isEmail(this.email);
    },

    passwordState() {
      if (this.forcePasswordState !== null) {
        return this.forcePasswordState;
      }

      return this.confirmedPassword.length === 0
        ? null
        : this.password.length >= 8 && this.password === this.confirmedPassword;
    },
  },

  watch: {
    password() {
      this.forcePasswordState = null;
    },

    confirmedPassword() {
      this.forcePasswordState = null;
    },
  },

  mounted() {
    this.$refs?.emailInput?.focus();
  },

  methods: {
    onSubmitEmail() {
      (this.showEmailState = true) && this.emailState && (this.step = 1);

      this.$nextTick(() => {
        this.$refs?.passwordInput?.focus();
      });
    },

    async onSubmit() {
      this.gtag_report_conversion();
      this.bing_report_conversion();

      try {
        this.loading = true;
        const registerData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
        };

        const { data } = await register(registerData);

        this.$store.dispatch("setUser", data.user);
        this.$store.dispatch("setToken", data.token);
        this.redirectToRoute(this, data.user);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
        this.step = 0;
      }
    },

    handleDone(user) {
      this.redirectToRoute(this, user);
    },

    async beforeChange({ index, resolve, reject }) {
      switch (index) {
        case 0: {
          if (
            this.password === this.confirmedPassword &&
            this.password.length >= 8
          ) {
            this.forcePasswordState = true;
            resolve();
          } else {
            this.forcePasswordState = false;
            reject();
          }

          break;
        }
        case 1:
          if (this.firstName.length > 0 && this.lastName.length > 0) {
            this.showNameState = false;
            resolve();
          } else {
            this.showNameState = true;
            reject();
          }
          break;

        default:
          resolve();
      }
    },
  },
};
</script>

<style lang="scss">
.divider {
  border-bottom: 1px solid #c8c8c8;
  position: relative;
  margin: 12px 0;

  .or {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #fff;
    padding: 0 10px;
  }
}

.small-text {
  font-family: "Roboto", "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
</style>
