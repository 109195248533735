import { checkout, upgrade } from "../api";
import { CONVERSION_PRICE_KEY } from "../constants";

export default {
  data() {
    return {
      error: "",
      upgradeLoading: false,
    };
  },

  computed: {
    basicPlan() {
      return this.$store.getters.basicPlan;
    },
  },

  methods: {
    storeConversionPrice(planText, yearlyToggle, coupon) {
      let currency_value = 0;

      switch (planText) {
        case "lite":
          currency_value = yearlyToggle ? "83.88" : "8.99";
          break;
        case "pro":
          currency_value = yearlyToggle ? "179.88" : "19.99";

          if (coupon === "20DISC") {
            currency_value = "15.99";
          }
          break;
        case "premium":
          currency_value = yearlyToggle ? "419.88" : "49.99";
          break;

        default:
          break;
      }

      localStorage.setItem(CONVERSION_PRICE_KEY, currency_value);
    },

    upgradeHandler(planText, yearlyToggle = false, coupon) {
      if (
        this.$route.query.coupon &&
        !(this.basicPlan && planText === "lite")
      ) {
        coupon = this.$route.query.coupon;
      }
      // if user is brand new, I.E on the basic plan
      // then we need to create a session to grab users
      // card information. If a user is already on a paid
      // plan and wants to upgrade their plan then we need to call
      // the upgrade endpoint since we already have card details

      // user is on basic plan, create session
      if (this.basicPlan) {
        // Store the conversion price so we can report it on the success page.
        this.storeConversionPrice(planText, yearlyToggle, coupon);

        this.createSession(planText, yearlyToggle, coupon);

        return;
      }

      // user is on a paid plan, upgrade plan
      this.upgradeCurrentPaidPlan({ planText, yearlyToggle, coupon });
    },

    async createSession(planText, yearlyToggle, coupon) {
      try {
        this.upgradeLoading = true;

        const checkoutData = {
          user: this.$store.state.user,
          plan: planText,
          yearly: yearlyToggle,
        };

        if (coupon) {
          checkoutData.coupon = coupon;
        }

        const session = await checkout(checkoutData);

        this.upgradeLoading = false;
        this.error = "";
        // redirect to external custom stripe payment page
        window.location.href = session.data.url;
      } catch (error) {
        this.upgradeLoading = false;
        this.error = error.response?.data?.error;
      }
    },

    async upgradeCurrentPaidPlan({
      planText,
      yearlyToggle,
      isDowngrade = false,
      coupon,
    }) {
      try {
        this.upgradeLoading = true;
        const upgradeData = {
          plan: planText,
          yearly: yearlyToggle,
        };

        if (coupon) {
          upgradeData.coupon = coupon;
        }

        const { data } = await upgrade(upgradeData);

        this.$store.dispatch("setUser", data.user);
        this.$router.push(
          `/?${isDowngrade ? "downgraded" : "upgraded"}=${planText}`
        );

        this.upgradeLoading = false;
        this.error = "";
      } catch (error) {
        this.upgradeLoading = false;
        this.error = error.response?.data?.error;
      }
    },
  },
};
