import { getEssay, feedback } from "@/api";

export default {
  data() {
    return {
      regradedEssay: {
        id: 1,
      },
      loadingEssay: false,
      loadingFeedback: false,
    };
  },

  methods: {
    async regrade(essay, needGetFullEssayData = true) {
      let essayData = essay;

      if (needGetFullEssayData) {
        try {
          this.loadingEssay = true;
          const { data } = await getEssay(essayData.id);
          essayData = data;
          this.loadingEssay = false;
        } catch (error) {
          this.loadingEssay = false;
          this.$bvToast.toast(
            "There was an error fetching the essay. Please try again.",
            {
              title: "Error",
              variant: "danger",
            }
          );
        }
      }

      if (!essayData.Rubric || essayData.Rubric.type === "Simple") {
        await this.regradeRequest(essayData);

        return true;
      }

      this.regradedEssay = essayData;
      this.$nextTick(() => {
        this.$bvModal.show("essay-regrade-modal");
      });

      return false;
    },

    async regradeRequest(essayData, additionalData = {}) {
      let payload = {
        name: essayData.name,
        content: essayData.content,
        gradeLevel: essayData.gradeLevel,
        type: essayData.type,
        ClassId: essayData.ClassId,
        intensity: essayData.intensity,
        LanguageId: essayData.LanguageId,
        RubricId: essayData.RubricId,
        tdaSources: essayData.tdaSources,
        tdaInstructions: essayData.tdaInstructions,
        ...additionalData,
      };

      try {
        this.loadingFeedback = true;
        await feedback([payload]);
        this.$bvToast.toast("The essay is queued to be regraded.", {
          title: "Success",
          variant: "success",
        });
        this.loadingFeedback = false;
      } catch (error) {
        this.loadingFeedback = false;
      }
    },
  },
};
