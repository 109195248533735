<script>
import { success } from "../api";
import { CONVERSION_PRICE_KEY } from "../constants";

export default {
  name: "SuccessView",
  data() {
    return {
      loading: true,
      error: "",
    };
  },
  async mounted() {
    try {
      const { data } = await success({
        sessionId: this.$route.query.session_id,
        user: this.$store.state.user,
      });

      this.$store.dispatch("setUser", data.user);
      this.reportConversion();

      this.loading = false;
      this.error = false;
    } catch (error) {
      this.loading = false;
      this.error = error.response?.data?.error;
    }
  },
  methods: {
    // Report conversion and delete conversion price from local storage
    reportConversion() {
      const conversion_price = localStorage.getItem(CONVERSION_PRICE_KEY);
      localStorage.removeItem(CONVERSION_PRICE_KEY);

      if (typeof gtag === "function") {
        window.gtag("event", "conversion", {
          send_to: "AW-16545029854/0VZ6CN7H8ccZEN69pNE9",
          value: conversion_price,
          currency: "USD",
        });
      }

      window.uetq = window.uetq || [];
      window.uetq.push("event", "purchase", {
        revenue_value: conversion_price,
        currency: "USD",
      });
    },
  },
};
</script>

<template>
  <b-container>
    <section>
      <b-row>
        <b-col>
          <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
          <div v-if="loading" class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>
              We received your payment. Hang tight, while we update your account
              info.
            </p>
          </div>
          <div v-else>
            <h1 class="mb-4">Payment successful</h1>
            <hr />
            <p class="mb-4">
              Awesome, you have successfully subscribed. Welcome onboard!
            </p>
            <b-button to="/" variant="info">Go home</b-button>
          </div>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
