<template>
  <div v-if="loading">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton type="input"></b-skeleton>
  </div>
  <eg-form-group v-else label="Class" required>
    <eg-dropdown
      :text="
        selectedClass
          ? selectedClass.name || selectedClass.descriptionHeading
          : 'Select class'
      "
    >
      <b-dropdown-text v-if="classes.length === 0">
        No classes were found on your account. You can only import assignments
        from classes created in Google classroom.
      </b-dropdown-text>
      <b-dropdown-item-button
        v-for="classData in classes"
        :key="classData.id"
        class="dropdown-item-wrapper"
        @click="() => selectClass(classData)"
      >
        {{ classData.name || classData.descriptionHeading }}
      </b-dropdown-item-button>
    </eg-dropdown>
  </eg-form-group>
</template>

<script>
import { getClassroomClasses } from "@/api";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import EgDropdown from "@/components/global/eg_dropdown.vue";
import errorHandler from "./errorHandler";

export default {
  components: {
    EgFormGroup,
    EgDropdown,
  },

  props: {
    value: {
      type: String,
    },
  },

  inject: ["selectedGoogleAccountId"],

  data() {
    return {
      loading: true,
      selectedClass: null,
      classes: [],
    };
  },
  methods: {
    selectClass(classData) {
      this.selectedClass = classData;
      this.$emit("input", classData.id);
    },

    async init() {
      try {
        this.loading = true;
        const { data } = await getClassroomClasses(
          this.selectedGoogleAccountId
        );
        this.classes = data.classes;

        let selectedClassData = (this.classes || 0)[0];

        if (this.value) {
          const classData = this.classes.find((c) => c.id === this.value);

          if (classData) {
            selectedClassData = classData;
          }
        }

        selectedClassData && this.selectClass(selectedClassData);
        this.loading = false;
        this.$emit("loaded");
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");
        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem fetching classes from your account. Please try again.",
        });
      }
    },
  },

  created() {
    this.init();
  },
};
</script>
