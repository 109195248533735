import api from "@/api/api";
import store from "@/store";

export const feedback = (payload) => {
  return api.post("/essays", { essays: payload });
};

export const extractFileData = (formData) => {
  return api.post("/extract", formData, {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${store.state.token}`,
    },
  });
};

export const getEssay = (essayId) => {
  return api.get("/essay", { params: { essayId } });
};

export const updateEssay = (payload) => {
  const { classId, ...rest } = payload;
  const updates = { ...rest };

  if (classId) {
    updates.ClassId = classId;
  }

  return api.put("/essay", updates);
};

export const deleteEssay = (essayId) => {
  return api.delete("/essay", { data: { essayId } });
};

export const getAllEssays = ({
  limit = 50,
  page,
  sortBy = "createdAt",
  sortDesc,
  filters,
}) => {
  if (sortBy === "Rubric") sortBy = "rubric";

  const sort = `${sortBy}:${sortDesc ? "DESC" : "ASC"}`;
  const params = { page, sort, limit };
  const filtersKeys = Object.keys(filters);

  if (filters && filtersKeys.some((key) => filters[key])) {
    filtersKeys.forEach((key) => {
      if (filters[key]) {
        params[key] = filters[key];
      }
    });
  }

  return api.get("/essays", {
    params,
  });
};

export const getCounters = () => {
  return api.get("/essays/counters");
};

export const exportToGoogle = (essaysIds = []) => {
  return api.put("/essays/export", { essaysIds });
};
