export const BASIC_PLAN = "basic";
export const LITE_PLAN = "lite";
export const PRO_PLAN = "pro";
export const PREMIUM_PLAN = "premium";

export const CLASSROOM_SCOPE = [
  "https://www.googleapis.com/auth/classroom.courses.readonly",
  "https://www.googleapis.com/auth/classroom.coursework.students",
  "https://www.googleapis.com/auth/classroom.rosters.readonly",
  "https://www.googleapis.com/auth/documents",
];

export const LOCAL_STORAGE_FEATURE_KEY = "essaygrader-version";
export const CONVERSION_PRICE_KEY = "essaygrader-conversion-price";

export const PLAN_INFO = {
  basic: {
    name: "Basic",
    essayLimit: 25,
    essayWordLimit: 1000,
    summaryLimit: 25,
    monthlyPrice: "$0/mo",
    yearlyPrice: "$0/mo",
    yearlyTotalPrice: "$0/year",
    description:
      "Our basic plan is meant for those who want to test out the product.",
    features: [
      "Grade 25 essays per month",
      "Summarize 25 essays per month",
      "Essay word count limit 1000 words",
      "Simple rubrics",
    ],
  },
  lite: {
    name: "Lite",
    essayLimit: 100,
    essayWordLimit: 2000,
    summaryLimit: 100,
    monthlyPrice: "$8.99/mo",
    yearlyPrice: "$6.99/mo",
    yearlyTotalPrice: "$83.88/year",
    description:
      "Our lite plan is perfect for those who don't grade essays a ton.",
    features: [
      "Grade 75 essays per month",
      "Summarize 75 essays per month",
      "Essay word count limit 2000 words",
      "Google Classroom integration",
      "Simple rubrics",
    ],
  },
  pro: {
    name: "Pro",
    essayLimit: 350,
    essayWordLimit: 3500,
    summaryLimit: 350,
    monthlyPrice: "$19.99/mo",
    yearlyPrice: "$14.99/mo",
    yearlyTotalPrice: "$179.88/year",
    description:
      "Our pro plan is our most popular plan. It's for those who grade a lot of essays per month.",
    features: [
      "Grade 350 essays per month",
      "Summarize 350 essays per month",
      "Essay word count limit 3500 words",
      "Google Classroom integration",
      "Advanced rubrics (higher grading accuracy)",
      "AI detection",
      "Bulk essay uploading",
      "Priority email support",
    ],
  },
  premium: {
    name: "Premium",
    essayLimit: 700,
    essayWordLimit: 7000,
    summaryLimit: 700,
    monthlyPrice: "$49.99/mo",
    yearlyPrice: "$34.99/mo",
    yearlyTotalPrice: "$419.88/year",
    description:
      "Our premium plan is for those true professionals who want the full capability of our product.",
    features: [
      "Grade 700 essays per month",
      "Summarize 700 essays per month",
      "Essay word count limit 7000 words",
      "Google Classroom integration",
      "Advanced rubrics (higher grading accuracy)",
      "AI detection",
      "Bulk essay uploading",
      "Priority chat support",
    ],
  },
};
